// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-templates-article-js": () => import("./../src/components/templates/article.js" /* webpackChunkName: "component---src-components-templates-article-js" */),
  "component---src-components-templates-page-js": () => import("./../src/components/templates/page.js" /* webpackChunkName: "component---src-components-templates-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-index-js": () => import("./../src/pages/app/index.js" /* webpackChunkName: "component---src-pages-app-index-js" */),
  "component---src-pages-app-login-js": () => import("./../src/pages/app/login.js" /* webpackChunkName: "component---src-pages-app-login-js" */),
  "component---src-pages-app-minutes-js": () => import("./../src/pages/app/minutes.js" /* webpackChunkName: "component---src-pages-app-minutes-js" */),
  "component---src-pages-commitee-js": () => import("./../src/pages/commitee.js" /* webpackChunkName: "component---src-pages-commitee-js" */),
  "component---src-pages-competitions-js": () => import("./../src/pages/competitions.js" /* webpackChunkName: "component---src-pages-competitions-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-fixtures-js": () => import("./../src/pages/fixtures.js" /* webpackChunkName: "component---src-pages-fixtures-js" */),
  "component---src-pages-gallery-js": () => import("./../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-history-js": () => import("./../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-rules-js": () => import("./../src/pages/rules.js" /* webpackChunkName: "component---src-pages-rules-js" */)
}

